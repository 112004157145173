import { PublicClientApplication, LogLevel, InteractionRequiredAuthError, BrowserUtils } from "@azure/msal-browser";

const CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID
const AZURE_B2C_DOMAIN = process.env.REACT_APP_AZURE_DOMAIN
const AZURE_B2C_URL = process.env.REACT_APP_AZURE_AD_B2C_URL
const PROTOCOL = window.location.protocol
const HOST = window.location.host
const MSALLOG=process.env.REACT_APP_MSAL_LOG
const logger={ 
  logLevel: LogLevel.Verbose,
  loggerCallback: (level, message, containsPii) => {
      if (containsPii) {
          return;
      }
      switch (level) {
          case LogLevel.Error:
              console.error(message);
              return;
          case LogLevel.Info:
              console.info(message);
              return;
          case LogLevel.Verbose:
              console.debug(message);
              return;
          case LogLevel.Warning:
              console.warn(message);
              return;
          default:
              console.log(message);
      }
  }}
export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: AZURE_B2C_URL + '/tfp/' + AZURE_B2C_DOMAIN + '/b2c_1_siupin',
    redirectUri: PROTOCOL + '//' + HOST,
    postLogoutRedirectUri: PROTOCOL + '//' + HOST,
    knownAuthorities: [AZURE_B2C_URL],
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    //storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  system: {
    tokenRenewalOffsetSeconds: 1200, //in seconds
    allowRedirectInIframe: true,
    loggerOptions: MSALLOG?logger:{}

} 
};

const B2CLog=(loginRequest,account)=>{
  try{
    msalInstance.acquireTokenSilent({
      scopes: loginRequest.scopes,
      account: account})
   }
  catch (e){
    //if InteractionRequiredAuthError
    msalInstance.acquireTokenRedirect({
      scopes: loginRequest.scopes,
      account: account
  })
  }
}

export const RESET_PASSWORD_URL = AZURE_B2C_URL + `/${AZURE_B2C_DOMAIN}/oauth2/v2.0/authorize?p=B2C_1_SSPR&client_id=${CLIENT_ID}&nonce=defaultNonce&redirect_uri=${PROTOCOL}//${HOST}&scope=openid&response_type=id_token&prompt=login`

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
// openid and profile is given by MSAL default.
export const loginRequest = {
  scopes: ['https://' + AZURE_B2C_DOMAIN + '/otis-customer-portal-api/user_impersonation']
};

export const runWithMsal = (msalInstance,app) => {
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0];
    msalInstance.handleRedirectPromise().then((tokenResponse) => {
    msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: account
    }).then((response) =>{
        localStorage.setItem('access.token', response.accessToken);
        localStorage.setItem('id.token', response.idToken);
        const jwtvalues=parseJwt(response.idToken);
        localStorage.setItem('CP_region', jwtvalues.extension_OtisONE_Region);
        localStorage.setItem('CP_country', jwtvalues.country.split("/", 1));
        app();
    }).catch((e)=>{
        // B2CLog(loginRequest,account);
        msalInstance.loginRedirect();
    });
}).catch((err) => {
        // Handle error
        if (err instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect({
               scopes: loginRequest.scopes,
               account: account 
            });
        }
        console.error(err);
    });
}

export const getTokenCP = () => {
  console.log("env test 2--->",process.env.REACT_APP_REGION_WISE_COUNTRYLIST);
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0]; 
    msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: account
    }).then((response) => {
        return response.accessToken
    }).catch((err) => {
      // B2CLog(loginRequest,account)
        msalInstance.loginRedirect();
    })
}

export const getLogout = () => {
	setTimeout(()=> {
		msalInstance.logoutRedirect({
      account: msalInstance.getActiveAccount(),
      postLogoutRedirectUri: PROTOCOL + '//' + HOST
    });
		localStorage.clear();
	}, 1000);	
}

export const onLogOut = () => {
	getLogout();
}

export const getToken = () => {
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0];
    msalInstance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        msalInstance
          .acquireTokenSilent({
            scopes: loginRequest.scopes,
            account: account
          })
          .then((response) => {
            localStorage.setItem('access.token', response.accessToken);
            localStorage.setItem('id.token', response.idToken);
            const jwtvalues=parseJwt(response.idToken);
            localStorage.setItem('CP_region', jwtvalues.extension_OtisONE_Region);
            localStorage.setItem('CP_country', jwtvalues.country.split("/", 1));
          })
          .catch((e) => {
          //  B2CLog(loginRequest,account)
            msalInstance.loginRedirect();
          });
      })
      .catch((err) => {
        // Handle error
        console.error(err);
      });
};

export const getRefreshedToken = () => {
    getToken();
}
const parseJwt=(token)=>{
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
return JSON.parse(jsonPayload);
};
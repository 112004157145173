// import 'core-js/es6/symbol'
// import 'core-js/es6/object'
// import 'core-js/es6/function'
// import 'core-js/es6/parse-int'
// import 'core-js/es6/parse-float'
// import 'core-js/es6/number'
// import 'core-js/es6/math'
// import 'core-js/es6/string'
// import 'core-js/es6/date'
// import 'core-js/es6/array'
// import 'core-js/es6/regexp'
// import 'core-js/es6/map'
// import 'core-js/es6/weak-map'
// import 'core-js/es6/set'
// import 'custom-event-polyfill'
/* above imports are for IE */

import { runWithMsal, msalInstance, RESET_PASSWORD_URL } from './msalConfig'
const DO_NOT_LOGIN = false

if (window.location.hash.indexOf('error') > 0) {
  //if error description === forgot password code redirect to reset pw screen
  if (window.location.hash.indexOf('AADB2C90118') > 0) {
    window.location.replace(RESET_PASSWORD_URL)
  } else if (
    window.location.hash.indexOf('AADB2C90091%3a') > 0 ||
    window.location.hash.indexOf('AADB2C90091') > 0 ||
    window.location.hash.indexOf('AADB2C90006%3a') > 0
  ) {
    //in case of redirect errors redirect to root
    window.location.replace('/')
  }
} 

runWithMsal(
  msalInstance,
  () => {

    if (window.location.hash.indexOf('error')== -1) {
      import('./indexApp.js')
      .then((Baz) => {
         console.log('Initializing....');
      });
  }
   // require('./indexApp.js')
  }
)
